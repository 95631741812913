import React from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { Close } from '@mui/icons-material';

const NotificationModal = ({ open, onClose }) => {
    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: "80vw", maxWidth: '400px', bgcolor: '#26262d', borderRadius: '20px', boxShadow: 24, p: '20px' }}>
                <div className='hide-scrollbar' style={{color:'#f2f3f8',marginBottom:'10px',height:'300px',overflow:'auto',}}><strong style={{ color:'#f2f3f8' }}>As of 1st Feb:</strong>
                    <div>There was a bug with the cookie run deckbuilder when you saved previously and the data may not have been saved.
                        <br/>
                        <br/>
                        The bug has been fixed.</div>
                </div>
                <Button onClick={onClose} sx={{width:'100%',bgcolor:'#ff6961'}}><Close/></Button>
            </Box>
        </Modal>
    );
};

export default NotificationModal;
